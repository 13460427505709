import { useTitle, useMediaQuery, useAsyncState, useFavicon } from '@vueuse/core';
import { useErrorHandler } from "@/helpers/useErrorHandler.js";
import { defineStore } from 'pinia';

import settingService from '@/services/setting.service.js';
import i18nService from '@/services/i18n.service.js';
import notificationService from '@/services/notification.service';
import favoriteService from "@/services/favorite.service";
const RERENDER_INTERVAL = 30000;
const FALLBACK_LANGUAGE = "en";
const SUPPORTED_LANGUAGES = ["en", "cs"];
const FORBIDDEN_PATHS = ["/login", "/upgrade"];
import { ref, computed, watch } from "vue";
export const useUI = defineStore('ui', () => {
  const modalCount = ref(0);
  /* settings */
  const { state: settings, execute: getSettings } = useAsyncState(
    () => settingService.getSettings().then(({ data }) => data.data),
    {},
    { 
      shallow: true,
      onError: () => {
        // If settings are not available (e.g. offline), force fetch translations
        getTranslations();
      },
      onSuccess: () => {
        if(!translations.value.length) {
          // If settings are fetched because it was set to the default, force fetch translations
          getTranslations();
        }
      },
    }
  );
  const getSetting = (name, fallbackValue) => settings.value?.[name] || fallbackValue;
  /* ui state */
  const globalTicker = ref(0);
  const redirectionPath = ref("");
  const setTitle = (title = "") => {
    const platformName = getSetting("PLATFORM_NAME", "Brinkee");
    useTitle(title, { title: "Brinkee", titleTemplate: `%s - ${ platformName }`});
  };
  const setFavicon = () => {
    const favicon = getSetting('LOGO_ICON_URL', '/logo-icon.png')
    useFavicon(favicon);
  };
  const nextTick = () => globalTicker.value++;
  const setRedirectionPath = (path = "/") => {
    if(FORBIDDEN_PATHS.some((forbiddenPath) => path.startsWith(forbiddenPath))) {
      return;
    }
    redirectionPath.value = path;
    return;
  };
  /* sidebar */
  const _isSidebarCollapsed = ref(false);
  const isSidebarCollapsed = computed(() => _isSidebarCollapsed.value && !isMobileRef.value); // mobile does not support collapsed sidebars
  const showFiltersRef = ref(false);
  const collapseSidebar = () => _isSidebarCollapsed.value = !_isSidebarCollapsed.value;

  /* toasts */
  const toastsRef = ref([]);
  const createToast = (toast) => {
    const id = new Date().getTime();
    toastsRef.value.push({ ...toast, id, });
  };
  const removeToast = (toastId) => {
    const toastIndex = toastsRef.value.findIndex((toast) => toast.id === toastId);
    toastsRef.value.splice(toastIndex, 1);
  };
  /* notifications */
  const { state: notifications, isLoading: notificationsLoading, execute: getNotifications } = useAsyncState(
    () => notificationService.getNotifications().then(({ data }) => data.data),
    [],
    { 
      shallow: false,
      resetOnExecute: false,
      immediate: false,
    }
  );
  const deleteNotification = async (notificationUid) => {
    try {
      const notificationIndex = notifications.value.findIndex((notification) => notification.uid === notificationUid);
      notifications.value.splice(notificationIndex, 1);
      await notificationService.deleteNotification(notificationUid);
      createToast({ 
        message: translate("UI_STORE.NOTIFICATION_DELETED"), 
        type: "green", 
      });
    } catch (error) {
      useErrorHandler(error);
    }
  };
  /* filters */
  const lastFiltersRef = ref({});
  const toggleFilters = () => showFiltersRef.value = !showFiltersRef.value;
  const setLastFilter = (tableName, filter) => lastFiltersRef.value[tableName] = filter;

  /* mobile */
  const _isMobileNavigationOpen = ref(false);
  const isMobileNavigationOpen = computed(() => _isMobileNavigationOpen.value && isMobileRef.value);
  const isMobileRef = useMediaQuery('(max-width: 1024px)');
  const toggleMobileNavigation = () => _isMobileNavigationOpen.value = !_isMobileNavigationOpen.value;
  const closeMobileNavigation = () => _isMobileNavigationOpen.value = false;

  /* translations */
  const systemLanguage = computed(() => getSetting("DEFAULT_LANGUAGE", FALLBACK_LANGUAGE));
  const userLanguage = ref();
  const language = computed(() => userLanguage.value || systemLanguage.value);
  const setLanguage = (newLanguage) => {
    if(!SUPPORTED_LANGUAGES.includes(newLanguage)) {
      createToast({ 
        message: "ERROR.LANGUAGE_NOT_SUPPORTED",
        type: "red",
      });
      return;
    }
    userLanguage.value = newLanguage
  };
  watch(language, () => getTranslations());
  watch(settings, () => setFavicon());
  const { state: translations, isLoading: areTranslationsLoading, execute: getTranslations } = useAsyncState(
    () => i18nService.getTranslations(language.value).then(({ data }) => data),
    [],
    { 
      shallow: true,
      immediate: false,
      onSuccess: () => document.documentElement.lang = language.value,
    }
  );
  const translate = (key, ...params) => {
    if(areTranslationsLoading.value) {
      return "";
    }
    const label = translations.value?.[key] || key;
    return !params.length ? label : label.replaceAll(/{(\d)}/g, (match, p1) => {
      return params[p1] || "";
    });
  };
  /** Favorites */
  const { state: favorites, isLoading: favoritesLoading, execute: getFavorites } = useAsyncState(
    () => favoriteService.getFavorites().then(({ data }) => data.data),
    [],
    { 
      shallow: false,
      resetOnExecute: false,
      immediate: false,
    }
  );
  const deleteFavorite = async (favoriteUid) => {
    try {
      const favoriteIndex = favorites.value.findIndex((favorite) => favorite.uid === favoriteUid);
      favorites.value.splice(favoriteIndex, 1);
      await favoriteService.deleteFavorite(favoriteUid);
      createToast({ 
        message: translate("UI_STORE.FAVORITE_DELETED"), 
        type: "green", 
      });
    } catch (error) {
      useErrorHandler(error);
    }
  };
  const createFavorite = async (payload) => {
    try {
      await favoriteService.createFavorite(payload);
      createToast({ 
        message: translate("UI_STORE.FAVORITE_CREATED"), 
        type: "green", 
      });
      getFavorites();
    } catch (error) {
      useErrorHandler(error);
    }
  };
  const getFavoriteUidFromPath = (path) => {
    const favorite = favorites.value.find((f) => f.path === path) || {};
    return favorite.uid;
  }
  return {
    modalCount,
    RERENDER_INTERVAL,
    globalTicker,
    nextTick,
    isMobileNavigationOpen,
    isSidebarCollapsed,
    language,
    setLanguage,
    translate,
    getTranslations,
    toasts: toastsRef,
    notifications,
    notificationsLoading,
    getNotifications,
    deleteNotification,
    createToast,
    removeToast,
    toggleMobileNavigation,
    collapseSidebar,
    closeMobileNavigation,
    getSetting,
    getSettings,
    setTitle,
    setFavicon,
    isMobile: isMobileRef,
    redirectionPath: redirectionPath,
    setRedirectionPath,
    showFilters: showFiltersRef,
    toggleFilters,
    lastFilters: lastFiltersRef,
    setLastFilter,
    favorites,
    favoritesLoading,
    getFavorites,
    createFavorite,
    deleteFavorite,
    getFavoriteUidFromPath,
  };
});
