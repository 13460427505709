<template>
    <section class="toast-container">
        <Toast v-for="(toast, toastIndex) of uiStore.toasts" :key="toastIndex" :toast="toast" />
    </section>
</template>

<script setup>
import { useUI } from "@/stores/ui.js";
import Toast from "@/components/Toast.vue";

const uiStore = useUI();
</script>

<style scoped>
.toast-container {
    position: fixed;
    z-index: 100000;
    width: 100%;
    top: 10px;
    pointer-events: none;
    display: grid;
    justify-items: center;
    justify-content: center;
    gap: 1vh;
    overflow-y: hidden;
    max-height: calc(100% - 10px);
}

</style>