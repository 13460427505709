import { createRouter, createWebHistory } from 'vue-router'
import { useAuth } from "@/stores/auth.js";
import { useUI } from "@/stores/ui.js";
import authService from '../services/auth.service.js';
import { useCookies } from '@vueuse/integrations/useCookies';

const UID_REGEX = "[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{18}";
const UID_OR_NEW_REGEX = `new|${ UID_REGEX }`;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // AUTH
    {
      path: "/login",
      name: "login",
      component: () => import('@/views/LoginView.vue'),
    },
    {
      path: '/',
      name: 'home',
      redirect: (to )=> {
        return { path: "/hub", };
      },
    },
    // UPGRADING
    {
      path: '/upgrading',
      name: 'upgrading',
      component: () => import('@/views/UpgradeView.vue'),
    },
    // FEED
    {
      path: "/hub",
      name: "hub",
      alias: ["/feed"],
      component: () => import('@/views/HubView.vue'),
      meta: {
        setRedirect: true,
        private: true,
      }
    },
    // PROFILE
    {
      path: "/profile",
      name: "profile",
      component: () => import('@/views/ProfileView.vue'),
      meta: {
        setRedirect: true,
        private: true,
      }
    },
    {
      path: "/platform",
      name: "platform",
      component: () => import('@/views/PlatformView.vue'),
      beforeEnter: (to, from) => {
        const authStore = useAuth();
        return authStore.hasRole("admin") || "/hub";
      },
      meta: {
        setRedirect: true,
        private: true,
      }
    },
    // DASHBOARD
    {
      path: `/dashboard/:uid(${ UID_REGEX })`,
      component: () => import('@/views/DashboardView.vue'),
      meta: {
        setRedirect: true,
        private: true,
      },
    },
    {
      path: `/:tableName`,
      children: [
        {
          path: "",
          redirect: (to) => {
            return {
              path: `/${ to.params.tableName }/list`,
            };
          },
        },
        {
          path: `new`,
          redirect: (to) => {
            return {
              path: `/${ to.params.tableName }/form/new`
            }
          }
        },
        {
          path: `list`,
          name: `dynamic-list`,
          component: () => import("@/views/ListView.vue"),
          meta: {
            setRedirect: true,
            private: true
          }
        },
        {
          path: `kanban`,
          name: `dynamic-kanban`,
          component: () => import("@/views/KanbanView.vue"),
          meta: {
            setRedirect: true,
            private: true,
          }
        },
        {
          path: `calendar`,
          name: `dynamic-calendar`,
          component: () => import("@/views/CalendarView.vue"),
          meta: {
            setRedirect: true,
            private: true,
          }
        },
        {
          path: `form`,
          redirect: (to) => {
            return { path: `/${ to.params.tableName }/form/new`, };
          },
        },
        {
          path: `form/:uid(${ UID_OR_NEW_REGEX })`,
          name: `dynamic-form`,
          component: () => import("@/views/FormView.vue"),
          meta: {
            private: true,
          }
        }
      ],
      meta: {
        private: true,
      }
    },
  ]
});

router.beforeEach(async (to, from) => {
  const authStore = useAuth();
  const cookies = useCookies(['csrf']);
  const uiStore = useUI();
  try {
    // trying to access a private page and we are not logged in on the frontend but have a csrf cookie
    if(to.meta.private && !authStore.loggedIn && cookies.get("csrf")) {
      const { data, } = await authService.getProfile();
      authStore.login(data.data);
      return;
    }
    // trying to access /login or /upgrading and we are not logged in on the frontend but have a csrf cookie
    if((to.path === "/login" || to.path === "/upgrading" && !to.query.force) && !authStore.loggedIn && cookies.get("csrf")) {
      const { data, } = await authService.getProfile();
      authStore.login(data.data);
      return {
        path: "/hub",
      };
    }
    // trying to access a private page but we are not logged in on the frontend and have no csrf cookie
    if(to.meta.private && !authStore.loggedIn && !cookies.get("csrf")) {
      uiStore.setRedirectionPath(to.fullPath);
      return {
        path: "/login",
      };
    }
    if(to.meta.private && authStore.loggedIn) {
      uiStore.setRedirectionPath(from.fullPath);
    }
    return;
  } catch (error) {
    // backend is down / critical error
    if(error.status === 502) {
      return { 
        path: "/upgrading",
        query: {
          force: true,
        }
      };
    } 
    // we are not authenticated and not on the login page
    if (error.status === 401 && to.path !== "/login") {
      return {
        path: "/login",
      };
    }
    return;
  }
  
})

export default router;
